export default [

    // Owner
    {
        owner : true,
        text:  'Products',
        icon: 'mdi-cart-variant',
        path: 'products',
        value: 'products',
        module : 'products',

    },
    {
        owner : true,
        text: 'Orders',
        icon: 'mdi-text-box-check-outline',
        value: 'orders',
        path: 'orders',
        module : 'products',

    },

    // User
    {
        user : true,
        text:  'Products',
        value: 'viewProducts',
        icon: 'mdi-cart-variant',
        path: 'viewProducts',
        module : 'products',
        permission:'MODULE_WESTUDY',
        id :'viewProducts',


    },
    {
        user : true,
        text:  'Cart' ,
        icon: 'mdi-cart',
        value: 'cart',
        path: 'cart',
        module : 'products',
        // counterValue: this.quantity
    },




]